/* LEVEL */

.level {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    --color-lowest-level: #518b14;
    --color-low-level: #92ce52;
    --color-medium-level: #f8c238;
    --color-high-level: #e37f22;
    --color-highest-level: #e11e1e;
}

.level-division {
    width: 10px;
    height: 8px;
    margin-right: 2px;
    background-color: #dfe1ec;
    border-radius: 3px;
}

.level-normal-lowest .level-division:nth-child(-n + 1) {
    background-color: var(--color-lowest-level);
}
.level-normal-low .level-division:nth-child(-n + 2) {
    background-color: var(--color-low-level);
}

.level-normal-medium .level-division:nth-child(-n + 3) {
    background-color: var(--color-medium-level);
}

.level-normal-high .level-division:nth-child(-n + 4) {
    background-color: var(--color-high-level);
}

.level-normal-highest .level-division:nth-child(-n + 5) {
    background-color: var(--color-highest-level);
}

/* INVERTED */
.level-inverted-lowest .level-division:nth-child(-n + 1) {
    background-color: var(--color-highest-level);
}
.level-inverted-low .level-division:nth-child(-n + 2) {
    background-color: var(--color-high-level);
}

.level-inverted-medium .level-division:nth-child(-n + 3) {
    background-color: var(--color-medium-level);
}

.level-inverted-high .level-division:nth-child(-n + 4) {
    background-color: var(--color-low-level);
}

.level-inverted-highest .level-division:nth-child(-n + 5) {
    background-color: var(--color-lowest-level);
}

.level-division:last-child {
    margin-right: 0;
}
