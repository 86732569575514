.generic-table {
    margin-bottom: 30px;
    .generic-table__header.grid-cols-25 {
        display: grid;
        grid-template-columns: repeat(
            25,
            minmax(0, 1fr)
        ); /* 25 equal columns */
    }

    .generic-table__header.grid-cols-26 {
        display: grid;
        grid-template-columns: repeat(
            26,
            minmax(0, 1fr)
        ); /* 26 equal columns */
    }
    .generic-table__header {
        border-bottom: 2px solid #c2c7d7;
        div {
            p {
                margin: 0;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                padding: 6px 10px;
            }

            div.can-sort {
                p {
                    cursor: pointer;

                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .generic-table__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        .body__row {
            border-bottom: 1px solid #c2c7d7;

            .select-cell {
                display: flex;
                align-items: center;

                & > div {
                    width: 100%;
                }
            }

            & > div {
                &:last-child {
                    border-right: none;
                }

                p {
                    margin: 0;
                    color: #484a53;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 12px 10px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .no-data {
            p {
                color: #484a53;
                font-size: 16px;
                line-height: 24px;
                padding: 12px 10px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 10px;
            }
        }
    }
}

.pagination-wrapper {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
    .pagination {
        margin: 0;
        .break,
        .previous,
        .next {
            margin: 0 5px;
            a {
                display: block;
                cursor: pointer;
                color: #484a53;
                line-height: 30px;
                outline: none !important;

                &:hover {
                    text-decoration: none;
                }
            }

            &.disabled {
                a {
                    cursor: default;
                }
            }
        }
        .next {
            margin-right: 0;
        }
        .page-item,
        .previous {
            margin: 0 5px;

            .page-link {
                outline: none !important;
                cursor: pointer;
                border-radius: 3px;
                color: #484a53;
                border-color: #484a53;
                padding: 0;
                width: 30px;
                font-size: 14px;
                line-height: 30px;
                text-align: center;
            }

            &.active {
                .page-link {
                    color: #4c72bd;
                    background-color: rgba(165, 168, 175, 0.2);
                    border-color: #4c72bd;
                }
            }

            &.disabled {
                opacity: 0.5;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}
