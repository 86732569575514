@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-white-hover-blue {
        @apply block py-1.5 px-3 border border-solid border-theme-tertiary rounded font-display text-theme-tertiary font-semibold hover:bg-theme-tertiary hover:text-white disabled:pointer-events-none;
    }

    .btn-dark-blue {
        @apply inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-button disabled:cursor-not-allowed disabled:opacity-50;
    }

    .animate {
        @apply transition-all ease-out;
    }

    .basic-transition {
        @apply transform transition-all duration-200 ease-in;
    }

    .slow-transition {
        @apply transform transition-all duration-500 ease-in;
    }
}

@layer utilities {
    .btn-main-green {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 12px 28px;
        border: 1px solid #8ec358;
        color: #8ec358;
        border-radius: 5px;
        text-align: center;
        transition: all 0.2s linear;
    }

    .btn-main-green:hover {
        color: #ffffff;
        background-color: #8ec358;
    }

    .btn-main-green[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .button-confirmation:hover svg {
        fill: #71a63b;
    }

    .table-hours input[type='number']::-webkit-inner-spin-button,
    .table-hours input[type='number']::-webkit-outer-spin-button,
    .table-hours input[type='number'].hide-arrow {
        appearance: none;
        margin: 0;
    }

    .tasks-table-body tr:hover {
        background: #f4f5e7;
        transition: 0.25s;
    }

    .ticket,
    .label {
        min-width: 60px;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 4px 7px 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
    }

    /* LABELS */

    .label {
        --color-bg-feature: #7902d7;
        --color-text-feature: #ffffff;

        --color-bg-not-feature: #f8c238;
        --color-text-not-feature: #ffffff;
    }

    .label-feature {
        display: block;
        margin: auto;
        color: var(--color-text-feature);
        background-color: var(--color-bg-feature);
        border-color: var(--color-bg-feature);
    }

    .label-not-feature {
        display: block;
        margin: auto;
        color: var(--color-text-feature);
        background-color: var(--color-bg-not-feature);
        border-color: var(--color-bg-not-feature);
    }

    /* CONFIDENCE */

    .confidence-level {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        --color-low-level: #e37f22;
        --color-medium-level: #f8c238;
        --color-high-level: #92ce52;
        --color-highest-level: #518b14;
    }

    .confidence-division {
        width: 10px;
        height: 8px;
        margin-right: 2px;
        background-color: #dfe1ec;
        border-radius: 3px;
    }

    .confidence-level-low .confidence-division:nth-child(-n + 2) {
        background-color: var(--color-low-level);
    }

    .confidence-level-medium .confidence-division:nth-child(-n + 3) {
        background-color: var(--color-medium-level);
    }

    .confidence-level-high .confidence-division:nth-child(-n + 4) {
        background-color: var(--color-high-level);
    }

    .confidence-level-highest .confidence-division:nth-child(-n + 5) {
        background-color: var(--color-highest-level);
    }

    .confidence-division:last-child {
        margin-right: 0;
    }

    /* @media (max-width: 1279px) {
        .task-row {
            display: grid;
            grid-template-columns: 40px 158px 221px 110px 126px 79px 495px 55px 142px 1fr;
        }
        .task-row-subtask {
            grid-template-columns: 13px 158px 221px 110px 126px 79px 495px 55px 142px 1fr;
            margin-left: 28px;
            transition: opacity 0.3s linear, height 0.3s step-start;
        }
    } */

    /* @media (min-width: 1280px) {
        .task-row {
            width: 100%;
            display: grid;
            grid-template-columns: 2.7% 10.5% 14.7% 7.3% 8.4% 5.3% 29% 3.7% 9.5% 1fr;
        }

        .task-row-subtask {
            grid-template-columns: 0.9% 10.7% 15% 7.5% 8.5% 5.4% 29.6% 55px 3.7% 1fr;
            margin-left: 28px;
            transition: opacity 0.3s linear, height 0.3s step-start;
        }
    } */

    /* .task-row {
        display: grid;
        grid-template-columns: 40px 158px 221px 110px 126px 79px 495px 55px 142px 1fr;
    } */

    /* .task-row-subtask {
        grid-template-columns: 0.9% 10.7% 15% 7.5% 8.5% 5.4% 31.6% 55px 3.7% 3%;
        margin-left: 28px;
        transition: opacity 0.3s linear, height 0.3s step-start;
    } */

    .hidden {
        opacity: 0;
        height: 0;
        overflow: hidden;
        border: none;
        transition-timing-function: linear, step-end;
    }

    .visuallyhidden {
        opacity: 0;
    }

    .rotate svg {
        transform: rotate(0);
        transition: 0.3s;
    }

    .visually-hidden:not(:focus):not(:active),
    input[type='checkbox'].visually-hidden,
    input[type='radio'].visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        white-space: nowrap;
        -webkit-clip-path: inset(100%);
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        overflow: hidden;
    }

    .row-selector {
        position: relative;
        width: 16px;
        height: 16px;
    }

    .row-selector::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: #e6e8ed;
        transform: translate(-50%, -50%);
    }

    .row-selector > input:checked {
        content: '';
        position: absolute;
        top: 1px;
        left: -35px;
        width: 22px;
        height: 11px;
        border: 4px solid var(--basic-white);
        border-top-style: none;
        border-right-style: none;
        transform: rotate(-45deg);
    }

    .react-tabs__tab-panel select:invalid {
        color: #d1d5db !important;
    }
    .react-tabs__tab-panel option[value=''][disabled] {
        color: #d1d5db !important;
    }
    .react-tabs__tab-panel option {
        color: #6b7280;
    }

    .button-with-grey-icon:hover .grey-icon {
        color: #7f8992;
    }

    .grey-icon {
        color: #c2c7d7;
    }
}

.btn {
    @apply px-4 py-1 my-1 rounded;
}

.markdown-wrapper p {
    margin-bottom: 12px;
}

.markdown-wrapper h1,
.markdown-wrapper h2,
.markdown-wrapper h3 {
    font-size: 48px;
}

.markdown-wrapper h4,
.markdown-wrapper h5,
.markdown-wrapper h6 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2px;
}

.markdown-wrapper a {
    cursor: pointer;
    text-decoration: underline;
}

.markdown-wrapper ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 30px;
}

.upload-input {
    border-radius: 5px;
    border: 1px solid #484a53;
    color: #484a53;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 3px 15px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s linear;
}

.upload-input:hover {
    color: #4c72bd;
    border-color: #4c72bd;
}

.upload-input input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: 0;
}

.fit-content {
    min-width: fit-content;
}

.highcharts-plot-line-label,
.highcharts-root,
.highcharts-container {
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
}

.highcharts-plot-line-label .label-tooltip-container .label-tooltip {
    width: 280px;
    height: 74px;
    overflow: hidden;
    border: 0px solid #cccccc;
    box-sizing: border-box;
    transform: translate(0, 100%);
}

.highcharts-plot-line-label:hover .label-tooltip-container .label-tooltip {
    width: 280px;
    height: 74px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    transform: translate(0, 0);
}

.highcharts-plot-line-label .label-tooltip-container {
    width: 0;
}

.highcharts-plot-line-label:hover .label-tooltip-container {
    width: 280px;
}
