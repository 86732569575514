.users-list {
    border: 1px solid #484a53;
    border-radius: 5px;
    margin-bottom: 100px;

    .rodal-dialog {
        .modal-content {
            border: none;
            .modal-title {
                font-size: 18px;
                line-height: 24px;
                color: #484a53;
                text-align: center;
            }

            .buttons-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 16px;
                button {
                    margin: 0 5px;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 7px 15px;
                    border: 1px solid;
                    border-radius: 5px;

                    &.delete {
                        border-color: #ee8282;
                        color: #ee8282;
                    }

                    &.close {
                        color: #4c72bd;
                        border-color: #4c72bd;
                    }
                }
            }
        }

        .rodal-close {
            display: none;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #484a53;

        .header__email,
        .header__org {
            padding: 0 15px;
            width: 20%;

            .value {
                font-size: 16px;
                line-height: 24px;
                color: #484a53;
                font-weight: 500;
            }
        }

        .header__created,
        .header__visited {
            width: 10%;

            .value {
                font-size: 16px;
                line-height: 24px;
                color: #484a53;
                font-weight: 500;
            }
        }

        .header__superadmin {
            width: 10%;

            .value {
                font-size: 16px;
                line-height: 24px;
                color: #484a53;
                font-weight: 500;
            }
        }

        .header__demo {
            .value {
                font-size: 16px;
                line-height: 24px;
                color: #484a53;
                font-weight: 500;
            }
        }
    }

    &__item {
        border-bottom: 1px solid #484a53;

        .preview {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 0;
        }

        .details {
            padding: 5px 15px;

            .add-permission-wrapper {
                .organisation-selector-wrapper {
                    width: 250px;
                    margin-top: 20px;
                }

                .permission-selector-wrapper {
                    width: 125px;
                    margin-top: 20px;
                }
            }

            .buttons-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .details-content {
                &__title {
                    width: 100%;

                    color: #484a53;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;

                    &.superadmin,
                    &.demo-user {
                        color: #4c72bd;
                    }
                }

                &__wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .permissions-list {
                        &__item {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .item__orgName {
                                color: #484a53;
                                margin-right: 10px;
                                font-weight: 500;
                                max-width: 200px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .remove-permission {
                                margin: 0 10px;
                            }
                        }
                    }

                    .save-button {
                        padding: 5px;
                        margin: 0 10px 0 30px;
                        transition: all 0s linear;
                        width: 150px;
                        border: 1px solid #8ec358;
                        color: #8ec358;

                        &[disabled] {
                            border-color: #484a53;
                            color: #484a53;
                        }
                    }
                }
            }

            .delete-user {
                width: 100px;
                margin-right: 15px;
                font-size: 16px;
                line-height: 24px;
                border: 1px solid #ee8282;
                border-radius: 5px;
                color: #ee8282;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .item__email,
    .item__org {
        padding: 0 15px;
        width: 20%;

        .value {
            font-size: 16px;
            line-height: 24px;
            color: #484a53;
            font-weight: 500;
        }
    }

    .item__visited,
    .item__created {
        width: 10%;
    }

    .item__superadmin {
        width: 10%;
    }

    .item__demoUser {
        width: calc(10% - 45px);
    }

    .toggler {
        width: 30px;
        margin-right: 15px;
    }
}
