.save-button {
    display: block;
    margin: 0 auto;
    background: transparent;
    border: 1px solid #8ec358;
    border-radius: 5px;
    color: #8ec358;
    font-weight: 500;
    padding: 8px 15px;
    outline: none;
    transition: all 0.2s linear;

    &[disabled] {
        color: #484a53;
        border-color: #484a53;
        opacity: 0.5;
    }
}
