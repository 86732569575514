.overview-info-block {
    margin-bottom: 70px;

    .overview-info__title {
        font-size: 24px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .info__description {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        span {
            font-size: 18px;
            line-height: 24px;
            color: #484a53;
            margin: 0 0 15px 20px;
            width: calc(100% - 100px);
        }
    }

    .overview-info__link {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #4c72bd;
        border: 1px solid #4c72bd;
        border-radius: 5px;
        padding: 5px 15px;
        transition: all 0.2s linear;
        width: 150px;
        margin: 0 auto;
        text-align: center;

        &:hover {
            text-decoration: none;
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            border-radius: 0;
        }
    }

    .info__rating {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .error-indicator {
            width: 100%;
        }

        .growth-wrapper {
            width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .growth__title {
                text-align: center;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #484a53;
                margin-bottom: 15px;
            }

            .score_growth {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .rating__title {
                    text-transform: capitalize;
                    font-weight: 500;
                }

                &:nth-child(2) {
                    height: 126px;
                }
            }
        }

        .rating-block {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(50% - 100px);

            .rating__title {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #484a53;
                margin-bottom: 15px;

                span {
                    color: #4c72bd;
                    font-weight: 500;
                    margin-left: 5px;
                }
            }

            .value {
                font-size: 18px;
                color: #484a53;
                font-weight: 500;
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 0 0px;
            }

            .score,
            .stars {
                margin-bottom: 20px;

                .score__title,
                .stars__title {
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 10px;
                    color: #484a53;
                }
            }
        }
    }
}
