html {
    scroll-behavior: smooth;
}

.login-page {
    .login-with-password {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .continue,
        .login {
            border: 1px solid #8ec358;
            font-weight: 500;
            border-radius: 5px;
            width: 250px;
            padding: 7px 10px;
            margin-bottom: 10px;
            color: #8ec358;
            transition: all 0.2s linear;

            &:hover {
                background-color: #8ec358;
                color: #fff;
            }
        }

        .back {
            border: 1px solid #4c72bd;
            font-weight: 500;
            border-radius: 5px;
            width: 250px;
            padding: 7px 10px;
            margin-bottom: 10px;
            color: #4c72bd;
            transition: all 0.2s linear;

            &:hover {
                background-color: #4c72bd;
                color: #fff;
            }
        }

        .email-intput-wrapper,
        .password-intput-wrapper {
            width: 100%;
            .email-input,
            .password-input {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                padding: 8px 50px 8px 15px;
                border-radius: 5px;
                border: 1px solid #484a53;
                outline: none;
                width: 100%;
                color: #484a53;
                transition: all 0.2s linear;
                margin-bottom: 15px;

                &::placeholder {
                    font-weight: normal;
                }

                &[readonly] {
                    background-color: #fff;
                }
                &:focus {
                    border-color: #4c72bd;
                    color: #4c72bd;
                    box-shadow: none;

                    &::placeholder {
                        color: #4c72bd;
                    }
                }
            }
        }
    }

    .or {
        margin: 20px;
        font-weight: 500;
        color: #484a53;
    }

    .saml-login {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .loader-wrapper {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }

        button,
        a {
            border: 1px solid #484a53;
            border-radius: 5px;
            width: 250px;
            padding: 7px 10px;
            margin-bottom: 10px;
            position: relative;
            overflow: hidden;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-weight: 500;
                margin-left: 10px;
                color: #484a53;
            }
        }
    }

    .login-link {
        font-weight: 500;
        color: #fff;
        font-size: 20px;
        line-height: 24px;
        padding: 7px 15px;
        border: 1px solid #8ec358;
        border-radius: 5px;
        background: #8ec358;
        width: 150px;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s linear;

        &:hover {
            background: #fff;
            color: #8ec358;
        }
    }
}

.page-content-wrapper {
    overflow-y: auto;
    height: calc(100vh - 61px);
    min-height: (550px - 61px);
}

.page-wrapper {
    overflow-y: hidden;
    height: 100vh;
    min-height: 100vh;
}

.repositories-page {
    .selected-repositories-list {
        .selected-repositories-list__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .add-repositories {
                button {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    padding: 11px 15px;
                    border: 1px solid;
                    border-radius: 5px;
                    text-align: center;
                    transition: all 0.2s linear;
                }
            }

            .providers-list {
                margin-top: 20px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #484a53;
                padding: 15px;
                border-radius: 5px;
                padding-bottom: 10px;

                .providers-list__title {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #484a53;
                }

                .provider {
                    border: 1px solid #484a53;
                    border-radius: 5px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 11px 15px;
                    width: 350px;
                    max-width: 30%;
                    cursor: pointer;

                    .provider-logo {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                        color: #484a53;
                        width: calc(100% - 35px);
                    }
                }
            }
        }

        .selected-repositories-list__body {
            .repositories-list {
                .list {
                    margin-bottom: 20px;
                    .empty {
                        font-size: 16px;
                        text-align: center;
                        font-weight: 500;
                        color: #484a53;
                    }

                    .selected-repository-wrapper {
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .selected-repository {
                        border: 1px solid #c2c7d7;
                        border-radius: 5px;
                        padding: 11px 15px;

                        .preview {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }

                        .info {
                            margin-top: 10px;
                            padding: 5px 0;

                            .title {
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 500;
                                margin-bottom: 10px;
                                color: #484a53;
                            }

                            .branch {
                                font-size: 16px;
                                line-height: 24px;
                                padding: 7px 15px;
                                border: 1px solid #484a53;
                                border-radius: 5px;
                                margin-bottom: 10px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                p {
                                    font-size: 16px;
                                    line-height: 24px;
                                    margin-right: 10px;
                                    color: #484a53;

                                    &.branch__name {
                                        width: calc(100% - 320px);
                                    }

                                    &.branch__ahead {
                                        width: 150px;
                                    }

                                    &.branch__behind {
                                        width: 150px;
                                        margin-right: 0;
                                    }
                                }
                            }

                            .job {
                                font-size: 16px;
                                line-height: 24px;
                                padding: 7px 15px;
                                border: 1px solid #484a53;
                                border-radius: 5px;
                                margin-bottom: 10px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: wrap;

                                button.job__name {
                                    color: #4c72bd;
                                }

                                p {
                                    font-size: 16px;
                                    line-height: 24px;
                                    margin-right: 10px;
                                    color: #484a53;

                                    &.job__name {
                                        width: calc(100% - 220px);
                                    }

                                    &.job__date {
                                        width: 100px;
                                        margin-right: 0;
                                    }
                                }

                                .job__status {
                                    width: 100px;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }

                        .repository-provider-logo {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                        }

                        .repository-name {
                            font-size: 16px;
                            line-height: 24px;
                            text-align: start;
                            font-weight: 500;
                            color: #484a53;
                            margin-right: 20px;
                            width: calc(50% - 25px);
                        }

                        .repository-statistick {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: calc(50% - 50px);

                            p {
                                font-size: 16px;
                                line-height: 24px;
                                margin-right: 20px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .arrow-box {
            position: relative;
            display: flex;
            justify-content: center;
            border: 1px solid #c2c7d7;
            border-radius: 5px;
            padding: 11px 15px;
        }

        .arrow-box:after,
        .arrow-box:before {
            bottom: 100%;
            border: solid transparent;
            content: '';
            position: absolute;
        }

        .arrow-box:after {
            border-bottom-color: white;
            border-width: 8px;
            margin-left: -8px;
        }

        .arrow-box:before {
            border-bottom-color: #c2c7d7;
            border-width: 11px;
            margin-left: -11px;
        }

        .arrow-left:after,
        .arrow-left:before {
            left: 15.8%;
        }

        .arrow-center:after,
        .arrow-center:before {
            left: 50%;
        }

        .arrow-right:after,
        .arrow-right:before {
            left: 84.2%;
        }
    }
    .repositories-list__header {
        .search {
            width: calc(100% - 150px);
            display: block;
        }

        .stock-repositries-list {
            .main {
                width: 100%;
            }
        }
    }
}

.user-settings-page {
    .user-avatar {
        border-radius: 5px;
        border: 1px solid #484a53;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .remove-logo {
            position: absolute;
            width: 24px;
            height: 24px;
            border: none;
            outline: none;
            padding: 0;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .upload-input {
            border-radius: 5px;
            border: 1px solid #484a53;
            color: #484a53;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 3px 15px;
            cursor: pointer;
            position: relative;
            transition: all 0.2s linear;

            &:hover {
                color: #4c72bd;
                border-color: #4c72bd;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                opacity: 0;
                z-index: 0;
            }
        }

        .drag-and-drop {
            border: 2px dashed #484a53;
            border-radius: 4px;
            margin-bottom: 10px;

            span {
                display: block;
                padding: 5px;
                text-align: center;
                color: #484a53;
            }
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .save-button {
        display: block;
        margin: 30px auto 15px;
        width: 100px;
        background: transparent;
        border: 1px solid #8ec358;
        border-radius: 5px;
        color: #8ec358;
        font-weight: 500;
        padding: 8px 15px;
        outline: none;
        transition: all 0.2s linear;

        &[disabled] {
            color: #484a53;
            border-color: #484a53;
            opacity: 0.5;
        }
    }

    .form-group {
        label,
        .sub-title {
            font-weight: 500;
            margin-bottom: 10px;
        }

        .form-control {
            border-color: #484a53;

            &[readonly] {
                background-color: #fff;
            }
            &:focus {
                border-color: #4c72bd;
                color: #4c72bd;
                box-shadow: none;

                &::placeholder {
                    color: #4c72bd;
                }
            }
        }
    }
}

.admin-page {
    .add-user {
        .select-organisation {
            .guest-organisation {
                color: #777a88;
                background-color: rgba(239, 239, 239, 0.3);
                line-height: 24px;
                font-size: 16px;
                padding: 5px 16px;
                border: 1px solid #484a53;
                border-radius: 5px;
            }
        }

        .add-user-form {
            .disabled-row {
                border-radius: 5px;
                background-color: #bbbcc4;
                border: 1px solid #484a53;
                margin-bottom: 20px;

                .disabled-notification {
                    padding-top: 20px;
                    color: #484a53;
                    font-weight: 500;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .create-organisation {
        .input-wrapper {
            width: 100%;
            margin-bottom: 30px;

            input {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                padding: 8px 50px 8px 15px;
                border-radius: 5px;
                border: 1px solid #484a53;
                outline: none;
                width: 100%;
                color: #484a53;
                transition: all 0.2s linear;

                &:focus {
                    color: #4c72bd;
                    border-color: #4c72bd;

                    &::placeholder {
                        color: #4c72bd;
                        font-weight: normal;
                    }
                }
                &::placeholder {
                    color: #484a53;
                    font-weight: normal;
                }
            }
        }

        .save-organisation {
            display: block;
            margin: 30px auto 15px;
            width: 100px;
            background: transparent;
            border: 1px solid #8ec358;
            border-radius: 5px;
            color: #8ec358;
            font-weight: 500;
            padding: 8px 15px;
            outline: none;
            transition: all 0.2s linear;

            &[disabled] {
                color: #484a53;
                border-color: #484a53;
                opacity: 0.5;
            }
        }
    }

    .existion-organisations-list {
        border-radius: 5px;
        border: 1px solid #484a53;
        margin-bottom: 30px;

        &__header {
            border-bottom: 1px solid #484a53;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            padding-top: 8px;
            padding-bottom: 8px;

            div {
                font-weight: 500;
                color: #484a53;
            }
        }

        &__body {
            .body__row {
                border-bottom: 1px solid #484a53;

                padding-top: 8px;
                padding-bottom: 8px;

                .Organisation-name,
                .Organisation-owner {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    line-height: 24px;
                }

                .organisation-status-indicator {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .organisation-delete {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .organisation-submit {
                    button {
                        font-size: 18px;
                        line-height: 24px;
                        padding: 6px 10px;
                        border: 1px solid #8ec358;
                        color: #8ec358;
                        border-radius: 5px;

                        &[disabled] {
                            color: #484a53;
                            border-color: #484a53;
                            opacity: 0.5;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

.team-page-wrapper {
    height: calc(100vh - 56px - 51px);

    .organisations-list {
        border-radius: 5px;
        border: 1px solid #4c72bd;
        margin-bottom: 30px;

        &__header {
            border-bottom: 1px solid #4c72bd;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &__body {
            .body__row {
                border-bottom: 1px solid #4c72bd;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                padding-top: 8px;
                padding-bottom: 8px;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .create-organisation {
        .input-wrapper {
            width: 100%;
            margin-bottom: 30px;

            input {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                padding: 8px 50px 8px 15px;
                border-radius: 5px;
                border: 1px solid #4c72bd;
                outline: none;
                width: 100%;
                color: #4c72bd;

                &::placeholder {
                    color: #c7d3ea;
                    font-weight: normal;
                }
            }
        }

        .save-organisation {
            display: block;
            margin: 30px auto 15px;
            width: 100px;
            background: transparent;
            border: 1px solid #8ec358;
            border-radius: 5px;
            color: #8ec358;
            font-weight: 500;
            padding: 8px 15px;
            outline: none;
            transition: all 0.2s linear;
        }
    }
}
