.settings-main-container {
    & > .row {
        height: 100%;
    }
}

.settings-content-wrapper {
    .settings-sub-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #484a53;
        margin: 0 0 15px 0;
    }

    .settings-tab-content {
        overflow-y: auto;
        position: relative;

        .update-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100vh - 106px);
            z-index: 1;
            display: none;
            justify-content: center;
            align-items: center;
            background-color: rgba($color: #fff, $alpha: 0.7);

            &.active {
                display: flex;
            }
        }

        &.users-tab {
            padding: 15px 0px;

            .users-list {
                border-radius: 5px;
                border: 1px solid #484a53;

                .no-users {
                    font-size: 16px;
                    line-height: 24px;
                    color: #4c72bd;
                }

                .user-avatar {
                    width: 42px;
                    height: 42px;
                    border-radius: 4px;
                    padding: 2px;
                    border: 1px solid #484a53;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .user-name {
                    width: 150px;
                }

                .user-email {
                    width: 200px;
                }

                .user-login {
                    width: 150px;
                }

                .user-permission {
                    width: 160px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .save-button {
                        padding: 5px;
                        width: 42px;
                        margin: 0 0 0 10px;

                        &[disabled] {
                            border-color: #484a53;
                        }
                    }

                    .status {
                        margin-left: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }

                .user-hours {
                    width: 160px;
                }

                .user-jira {
                    width: calc(100% - 910px);
                }

                .value {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 0 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &__header {
                    background-color: #848794;
                    border-bottom: 1px solid #484a53;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 6px 15px;

                    .user-avatar {
                        height: 24px;
                        border: none;
                        padding: 0;
                    }

                    p {
                        color: #fff;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                &__body {
                    .user {
                        border-bottom: 1px solid #484a53;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 6px 15px;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        &.saml-tab {
            padding: 15px 0px;

            .enable-switcher {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 20px;

                .switcher__value {
                    line-height: 34px;
                    font-weight: 500;
                    color: #484a53;
                    margin-left: 10px;
                }
                .switcher__title {
                    line-height: 34px;
                    font-weight: 500;
                    color: #484a53;
                    margin-right: 10px;
                }
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 60px;
                    height: 34px;
                }

                /* Hide default HTML checkbox */
                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                /* The slider */
                .slider {
                    position: absolute;

                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #484a53;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }
                .slider.pointer {
                    cursor: pointer;
                }

                .slider:before {
                    position: absolute;
                    content: '';
                    height: 26px;
                    width: 26px;
                    left: 4px;
                    bottom: 4px;
                    background-color: white;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }

                input:checked + .slider {
                    background-color: #4c72bd;
                }

                input:focus + .slider {
                    box-shadow: 0 0 1px #4c72bd;
                }

                input:checked + .slider:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }

                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
                }

                .slider.round:before {
                    border-radius: 50%;
                }
            }

            .input-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 0;

                label {
                    font-weight: 500;
                    color: #484a53;
                    margin-bottom: 10px;
                }

                input {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    padding: 8px 50px 8px 15px;
                    border-radius: 5px;
                    border: 1px solid #484a53;
                    outline: none;
                    width: 100%;
                    color: #484a53;
                    transition: all 0.2s linear;

                    &:focus {
                        border-color: #4c72bd;
                        color: #4c72bd;
                    }
                }

                textarea {
                    font-size: 16px;
                    height: 100px;
                    line-height: 24px;
                    font-weight: 500;
                    padding: 8px 50px 8px 15px;
                    border-radius: 5px;
                    border: 1px solid #484a53;
                    outline: none;
                    width: 100%;
                    color: #484a53;
                    transition: all 0.2s linear;

                    &:focus {
                        border-color: #4c72bd;
                        color: #4c72bd;
                    }
                }
            }

            .provider-name,
            .certificate {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: row;

                .input-wrapper,
                .textarea-wrapper {
                    width: calc(100% - 60px);
                }

                .delete-provider {
                    margin-top: 26px;
                    padding: 8px;
                    border: 1px solid #484a53;
                    border-radius: 5px;
                    transition: all 0.2s linear;

                    svg {
                        fill: #484a53;
                        transition: all 0.2s linear;
                    }

                    &:hover {
                        border-color: #ee8282;

                        svg {
                            fill: #ee8282;
                        }
                    }
                }
            }

            .certificates {
                margin-bottom: 20px;

                .delete-certificate {
                    padding: 8px;
                    border: 1px solid #484a53;
                    border-radius: 5px;
                    transition: all 0.2s linear;

                    svg {
                        fill: #484a53;
                        transition: all 0.2s linear;
                    }

                    &:hover {
                        border-color: #ee8282;

                        svg {
                            fill: #ee8282;
                        }
                    }
                }

                .certificates__title {
                    line-height: 34px;
                    font-weight: 500;
                    color: #484a53;
                    margin-right: 10px;
                }
            }
        }

        &.schedule-tab {
            padding: 15px 0px;

            .git-provider-schedule {
                padding: 15px;
                border-radius: 5px;
                border: 1px solid #484a53;
                margin-bottom: 15px;
                margin-top: 20px;
                position: relative;

                .update-loading {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba($color: #fff, $alpha: 0.5);
                }

                .git-provider__name {
                    margin-bottom: 10px;
                    display: flex;
                    flex-wrap: wrap;

                    .repositories {
                        width: 100%;
                        color: #484a53;
                        margin-top: 10px;
                    }

                    .value {
                        font-size: 18px;
                        line-height: 24px;
                        color: #484a53;
                        font-weight: 500;
                        margin-left: 10px;
                    }
                }

                .schedule-value-wrapper {
                    .schedule-selector {
                        .input-wrapper {
                            input {
                                border: none;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }

                    .schedule-value {
                        margin-bottom: 10px;
                        color: #484a53;

                        span.value {
                            color: #4c72bd;
                            font-weight: 500;
                        }
                    }

                    .last-run {
                        color: #484a53;
                        margin-bottom: 10px;

                        span.date {
                            color: #4c72bd;
                            font-weight: 500;
                        }
                    }
                }

                input[type='range'] {
                    height: 25px;
                    -webkit-appearance: none;
                    margin: 10px 0;
                    width: 100%;
                }
                input[type='range']:focus {
                    outline: none;
                }
                input[type='range']::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 5px;
                    cursor: pointer;

                    box-shadow: 0px 0px 0px #484a53;
                    background: #484a53;
                    border-radius: 1px;
                    border: 0px solid #484a53;
                }
                input[type='range']::-webkit-slider-thumb {
                    box-shadow: 0px 0px 0px #484a53;
                    border: 2px solid #484a53;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    background: #4c72bd;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -7px;
                }
                input[type='range']:focus::-webkit-slider-runnable-track {
                    background: #484a53;
                }
                input[type='range']::-moz-range-track {
                    width: 100%;
                    height: 5px;
                    cursor: pointer;
                    box-shadow: 0px 0px 0px #000000;
                    background: #2497e3;
                    border-radius: 1px;
                    border: 0px solid #000000;
                }
                input[type='range']::-moz-range-thumb {
                    box-shadow: 0px 0px 0px #000000;
                    border: 1px solid #2497e3;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    background: #a1d0ff;
                    cursor: pointer;
                }
                input[type='range']::-ms-track {
                    width: 100%;
                    height: 5px;
                    cursor: pointer;
                    background: transparent;
                    border-color: transparent;
                    color: transparent;
                }
                input[type='range']::-ms-fill-lower {
                    background: #2497e3;
                    border: 0px solid #000000;
                    border-radius: 2px;
                    box-shadow: 0px 0px 0px #000000;
                }
                input[type='range']::-ms-fill-upper {
                    background: #2497e3;
                    border: 0px solid #000000;
                    border-radius: 2px;
                    box-shadow: 0px 0px 0px #000000;
                }
                input[type='range']::-ms-thumb {
                    margin-top: 1px;
                    box-shadow: 0px 0px 0px #000000;
                    border: 1px solid #2497e3;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    background: #a1d0ff;
                    cursor: pointer;
                }
                input[type='range']:focus::-ms-fill-lower {
                    background: #2497e3;
                }
                input[type='range']:focus::-ms-fill-upper {
                    background: #2497e3;
                }
            }

            .git-provider-schedule {
                .create-schedule-toggler {
                    display: block;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 8px 15px;
                    color: #4c72bd;
                    border: 1px solid #4c72bd;
                    border-radius: 5px;
                }

                .save-schedule {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 3px 15px;
                    color: #8ec358;
                    border: 1px solid #8ec358;
                    border-radius: 5px;
                    margin: 15px 0;
                }

                .cheate-schedule-wrapper {
                    display: flex;
                    flex-direction: column;

                    .cheate-schedule__selector {
                        position: relative;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .control {
                            height: 30px;
                            padding-left: 30px;

                            .control_indicator {
                                top: 5px;
                            }
                        }

                        span {
                            line-height: 30px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &.kudos-tab {
            padding: 15px 0px;

            .emoji-settings {
                margin-top: 20px;
            }

            .emoji-save-wrapper {
                padding: 10px 15px;
                margin: 15px auto;
            }

            .selected-emoji-list-block {
                .selected-emoji-list {
                    display: flex;
                    justify-content: flex-start;

                    .selected-emoji {
                        position: relative;
                        width: 80px;
                        height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &__remove {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }
            .add-workspace {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                &__description {
                    display: flex;
                    margin: 0 auto 15px;
                    text-align: center;
                    color: #484a53;
                    max-width: 400px;
                }

                .add-slack-button {
                    margin-bottom: 5px;
                }
            }

            .workspaces-list {
                border: 1px solid #484a53;
                overflow: hidden;
                border-radius: 5px;
                &__header {
                    border-bottom: 1px solid #484a53;
                    background-color: #c7d3ea;

                    p {
                        margin: 0;
                        color: #484a53;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                }

                &__body {
                    .workspace {
                        border-bottom: 1px solid #484a53;

                        &:nth-child(even) {
                            background-color: #e4e4e7;
                        }

                        &:last-child {
                            border-bottom: none;
                            border-radius: 0 0 5px 5px;
                        }

                        p {
                            margin: 0;
                            color: #484a53;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            padding-top: 7px;
                            padding-bottom: 7px;
                        }
                    }
                }
            }
        }

        &.sprint-tab {
            padding: 15px 0px;
            position: relative;
            z-index: 1;

            .react-datepicker {
                border-color: #484a53;

                &__month-container {
                    .react-datepicker__header {
                        border-color: #484a53;

                        .react-datepicker__current-month {
                            color: #484a53;
                        }
                    }

                    .react-datepicker__month {
                        .react-datepicker__week {
                            .react-datepicker__day {
                                color: #484a53;

                                &:focus {
                                    outline-color: #484a53;
                                }

                                &--today {
                                    color: #8ec358 !important;
                                }

                                &--in-range,
                                &--keyboard-selected,
                                &--in-selecting-range {
                                    background-color: #4c72bd;
                                    color: #fff;
                                }

                                &--selected {
                                    color: #fff;
                                    background: #4c72bd;

                                    &:focus {
                                        outline-color: #484a53;
                                    }
                                }

                                &.sprint-day {
                                    border-radius: 0.3rem;
                                    color: #fff;
                                }

                                &.color-1 {
                                    background-color: #d8d95c;
                                }

                                &.color-2 {
                                    background-color: #ee8282;
                                }

                                &.color-3 {
                                    background-color: #4c72bd;
                                }
                            }
                        }
                    }
                }
            }

            .selected-sprints {
                margin-bottom: 15px;

                .selected-sprints-content {
                    min-height: 42px;
                    display: flex;
                    justify-content: space-between;

                    .calenar-wrapper {
                        width: 250px;
                    }

                    .calendar-values {
                        width: calc(100% - 250px);
                        padding: 0 15px;

                        .data-value,
                        .duration-value,
                        .sprints-quantity-value {
                            margin-bottom: 10px;

                            .values-title {
                                font-weight: 500;
                                margin-bottom: 5px;
                            }

                            .value {
                                color: #8ec358;
                            }
                        }
                    }

                    .error-indicator {
                        width: 100%;
                    }
                }
            }

            .select-new-sprint {
                .description {
                    margin-bottom: 15px;
                }

                .select-new-sprint-content {
                    display: flex;
                    justify-content: space-between;

                    .calenar-wrapper {
                        width: 250px;
                    }

                    .calendar-values {
                        width: calc(100% - 250px);
                        padding: 0 15px;

                        div {
                            margin-bottom: 15px;
                            p {
                                font-size: 16px;
                                line-height: 24px;
                                color: #484a53;
                                font-weight: 500;
                                margin-bottom: 5px;
                            }

                            .date-value {
                                color: #8ec358;

                                &.no-value {
                                    color: #dc3545;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.api-key-tab {
            padding: 15px 0px;

            .Toastify__toast--success {
                background-color: #8ec358;
                border-radius: 5px;
                padding: 0 15px;
            }

            .no-value {
                color: #4c72bd;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 50px;
            }

            .description {
                margin: 0 auto 15px 0;
                color: #484a53;
            }

            .rodal-dialog {
                .modal-content {
                    border: none;
                    .modal-title {
                        font-size: 18px;
                        line-height: 24px;
                        color: #484a53;
                        text-align: center;
                    }

                    .buttons-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 16px;
                        button {
                            margin: 0 5px;
                            font-size: 16px;
                            line-height: 24px;
                            padding: 7px 15px;
                            border: 1px solid;
                            border-radius: 5px;

                            &.delete {
                                border-color: #ee8282;
                                color: #ee8282;
                            }

                            &.close {
                                color: #4c72bd;
                                border-color: #4c72bd;
                            }
                        }
                    }
                }

                .rodal-close {
                    display: none;
                }
            }

            .add-new-key {
                &__title {
                    font-size: 18px;
                    color: #484a53;
                    line-height: 24px;
                    font-weight: 500;
                    margin-bottom: 15px;
                }

                &__button {
                    color: #8ec358;
                    border: 1px solid #8ec358;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 7px 15px;
                }
            }

            .keys-list {
                &__title {
                    font-size: 18px;
                    color: #484a53;
                    line-height: 24px;
                    font-weight: 500;
                    margin-bottom: 15px;
                }
            }

            .input-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                input {
                    width: calc(100% - 380px);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .copy-api-key {
                    padding: 11px;
                    height: 42px;
                    width: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #4c72bd;
                    background-color: transparent;
                    outline: none;
                    transition: all 0.1s linear;

                    &:active {
                        box-shadow: 2px 2px 5px 0px rgba(76, 114, 189, 1);
                    }
                }

                .status {
                    width: 50px;
                    display: flex;
                    justify-content: center;
                }

                .single-value {
                    width: 100%;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 9px 10px;
                    color: #484a53;
                    border-color: #484a53;
                    font-weight: 500;
                }

                .delete-api-key {
                    padding: 11px;
                    height: 42px;
                    width: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #b21f2d;
                    background-color: transparent;
                    outline: none;
                    transition: all 0.1s linear;

                    &:active {
                        box-shadow: 2px 2px 5px 0px rgba(78, 31, 45, 1);
                    }
                }
            }
        }

        &.swarm-tab {
            padding: 15px 0px;

            .swarm-buttons-group {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    border: 1px solid #8ec358;
                    border-radius: 5px;
                    padding: 13px 15px;
                    min-width: 150px;
                    color: #8ec358;
                    margin: 0 15px;

                    &[disabled] {
                        color: #484a53;
                        border-color: #484a53;
                        opacity: 0.5;
                    }
                }
            }

            .provider-jobs {
                .jobs-list {
                    border: 1px solid #484a53;
                    margin-bottom: 20px;

                    .jobs-list__header {
                        border-bottom: 1px solid #484a53;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background: #adafb8;

                        .header__job {
                            width: calc(100% - 250px);
                            font-size: 16px;
                            line-height: 24px;
                            color: #484a53;
                            padding: 13px 15px;

                            font-weight: 500;
                        }

                        .header__status {
                            width: 100px;
                            font-size: 16px;
                            line-height: 24px;
                            color: #484a53;
                            font-weight: 500;
                            padding: 13px 15px;
                        }

                        .header__date {
                            width: 150px;
                            font-size: 16px;
                            line-height: 24px;
                            color: #484a53;
                            font-weight: 500;
                            padding: 13px 15px;
                        }
                    }
                    .jobs-list__body {
                        .body__row {
                            border-bottom: 1px solid #484a53;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            &:last-child {
                                border-bottom: none;
                            }

                            .row__job {
                                width: calc(100% - 250px);
                                font-size: 16px;
                                line-height: 24px;
                                color: #484a53;
                                padding: 13px 15px;

                                font-weight: 500;
                            }

                            .row__status {
                                width: 100px;
                                font-size: 16px;
                                line-height: 24px;
                                color: #484a53;
                                font-weight: 500;
                                padding: 13px 15px;
                            }

                            .row__date {
                                width: 150px;
                                font-size: 16px;
                                line-height: 24px;
                                color: #484a53;
                                font-weight: 500;
                                padding: 13px 15px;
                            }
                        }
                    }
                }
            }

            .swarm-tabs-switcher {
                display: flex;
                justify-content: space-evenly;
                padding: 20px 0;
                button {
                    width: 200px;
                    text-align: center;
                    color: #484a53;
                    font-size: 20px;
                    padding: 5px 15px;
                    border-bottom: 1px solid #484a53;
                    &.active {
                        border-bottom: 1px solid #484a53;
                    }
                }
            }

            .providers-list-wrapper {
                display: flex;
                justify-content: flex-start;
                .providers-list {
                    &.single-value {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 6px 15px;
                        border: 1px solid #484a53;
                        border-radius: 5px;

                        .provider-name {
                            font-size: 16px;
                            line-height: 24px;
                            color: #484a53;
                            margin-left: 15px;
                        }
                    }
                }
            }

            .swarm-provider {
                .swarm-schedule {
                    padding: 15px 0;

                    .value {
                        margin-top: 10px;
                    }
                }

                & > .title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 15px;
                    svg {
                        margin-right: 15px;
                    }
                    h4 {
                        margin: 0;
                        color: #484a53;

                        span {
                            color: #4c72bd;
                            font-weight: 500;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        &.teams-tab {
            padding: 15px 0px;

            .add-team {
                display: flex;
                margin: 30px auto 15px;
                flex-direction: column;
                align-items: center;
                background-color: transparent;
                border: none;
                outline: none;
                svg {
                    margin-bottom: 15px;
                    color: #8ec358;
                    font-weight: 500;
                }
            }

            .teams-list {
                .team {
                    padding: 15px;
                    border: 1px solid #484a53;
                    background-color: #e4e4e7;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    position: relative;

                    .buttons-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .save-button {
                            margin: 0 10px;
                        }

                        .remove-team {
                            display: block;
                            margin: 0 10px;
                            width: 150px;
                            background: transparent;
                            border: 1px solid #ee8282;
                            border-radius: 5px;
                            color: #ee8282;
                            font-weight: 500;
                            padding: 8px 15px;
                            outline: none;
                            transition: all 0.2s linear;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .input-wrapper {
                        margin-top: 15px;
                    }

                    .team-name-label {
                        position: relative;
                    }
                }

                .users-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    position: relative;

                    .add-users {
                        background-color: transparent;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        outline: none;
                        padding: 0;
                        border: 1px solid #484a53;
                        position: absolute;
                        top: calc(50% - 15px);
                        left: calc(50% - 15px);

                        &.active {
                            border-color: #4c72bd;
                        }

                        &[disabled] {
                            opacity: 0.5;
                        }
                    }

                    .users-list-wrapper {
                        width: calc(50% - 50px);
                    }

                    .add-new-user {
                        text-align: center;
                        color: #484a53;
                        font-weight: 500;
                        margin-top: 15px;

                        button {
                            color: #4c72bd;
                            font-weight: 500;
                            border: none;
                            background-color: transparent;
                            outline: none;
                        }
                    }

                    .users-list {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        border-radius: 5px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 400px;
                        border: 1px solid #484a53;

                        .user {
                            width: 100%;
                            position: relative;
                            padding: 8px 15px 7px;
                            background-color: #fff;
                            border-bottom: 1px solid;
                            height: 40px;

                            &:last-child {
                                border-bottom: none;
                            }

                            .user-name {
                                font-size: 16px;
                                line-height: 24px;
                                margin: 0;
                                padding: 0;
                                width: calc(100% - 30px);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .user-status {
                                position: absolute;
                                right: 8px;
                                top: 8px;
                                width: 24px;
                                height: 24px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg.selected {
                                    cursor: pointer;
                                }

                                .checkbox-circle {
                                    width: 20px;
                                    height: 20px;
                                    display: block;
                                    border-radius: 50%;
                                    border: 1px solid #4c72bd;
                                    cursor: pointer;
                                }

                                .remove-user {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0;
                                    border: none;
                                    outline: none;
                                    background-color: transparent;
                                    svg {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sprint-insights-tab {
            padding: 15px 0px;
        }
    }
}

.organisation-tab {
    padding: 15px 0px;

    .save-button-wrapper {
        margin: 30px auto 15px;
    }

    .loading-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background-color: rgba($color: #fff, $alpha: 0.7);

        &.active {
            display: flex;
        }
    }

    .organisation-name {
        margin-bottom: 15px;

        .status-indicator-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            width: 42px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .organisation-logo-wrapper {
        .organisation-logo {
            position: relative;
        }

        .status-indicator-wraper {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .loading-wrapper {
                background-color: rgba(255, 255, 255, 0.7);
                width: 100%;
                height: 100%;
                display: flex;
            }
        }

        .logo-wrapper {
            min-width: 240px;
            height: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .remove-logo {
                position: absolute;
                width: 24px;
                height: 24px;
                border: none;
                outline: none;
                padding: 0;
                top: 10px;
                right: 10px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }

    .drag-and-drop {
        min-width: 240px;
        min-height: 200px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;

        .dnd-placeholder {
            margin-bottom: 15px;
        }

        svg {
            margin-bottom: 15px;
        }

        .upload-input {
            border-radius: 5px;
            border: 1px solid #484a53;
            color: #484a53;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 3px 15px;
            cursor: pointer;
            position: relative;
            transition: all 0.2s linear;

            &:hover {
                color: #4c72bd;
                border-color: #4c72bd;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                opacity: 0;
                z-index: 0;
            }
        }
    }
}
