.modified-lines-chart {
    .form-wrapper {
        .title-wrapper {
            display: flex;
            justify-content: flex-end;
            .open-date-range {
                background: transparent;
                outline: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 20px;
                    line-height: 32px;
                    display: block;
                    margin-right: 10px;
                }

                svg {
                    color: #17a2b8;

                    fill: #17a2b8;
                }
            }
        }
    }
}
