.no-arrows {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }
}

.time-period-wrapper {
    border-top: 1px solid #dee2e6;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #484a53;
    z-index: 1;

    &.is-open {
        display: block;
    }

    .nav-tabs {
        border: none;
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .nav-link {
            margin: 0 10px 0 0;
            cursor: pointer;
            border: none;
            border-bottom: 1px solid transparent;
            font-size: 16px;
            line-height: 20px;
            padding: 5px 15px;
            min-width: 100px;
            text-align: center;
            transition: all 0.2s linear;
            background-color: transparent;
            color: #fff;

            &.active {
                border-bottom: 1px solid #fff;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .set-time-period-button {
        color: #fff;
        border: 1px solid #fff;
        align-self: flex-end;
        font-size: 16px;
        list-style: 24px;
        padding: 7px 10px;
        border-radius: 5px;
        margin-bottom: 15px;
        text-align: center;
        width: 100px;

        &[disabled] {
            opacity: 0.2;
        }
    }

    .quick-select-date-range {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding: 17px 0 18px;

        li {
            margin: 0 10px 0 0;

            button {
                background: transparent;
                color: #fff;
                outline: none;
                border: none;
                border-bottom: 1px solid transparent;
                transition: all 0.2s linear;
                font-size: 14px;
                line-height: 16px;
                padding: 3px 5px;
                border-radius: 5px;

                &.active {
                    border-bottom: 1px solid #fff;
                    border-radius: 5px 5px 0 0;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

    .sprints-selector-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 0;

        .no-sprints {
            color: #fff;
        }

        button {
            background: transparent;
            color: #fff;
            outline: none;
            border: none;
            border-bottom: 1px solid transparent;
            transition: all 0.2s linear;
            font-size: 14px;
            line-height: 16px;
            padding: 3px 5px;
            border-radius: 5px;
            margin: 0 10px 0 0;

            &.active {
                border-bottom: 1px solid #fff;
                border-radius: 5px 5px 0 0;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }

    .global-date-selector-content {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0;
    }
}
