.react-datepicker {
    border-color: #484a53;

    &__month-container {
        .react-datepicker__header {
            border-color: #484a53;

            .react-datepicker__current-month {
                color: #484a53;
            }
        }

        .react-datepicker__month {
            .react-datepicker__week {
                .react-datepicker__day {
                    color: #484a53;

                    &:focus {
                        outline-color: #484a53;
                    }

                    &--today {
                        color: #8ec358 !important;
                    }

                    &--in-range,
                    &--keyboard-selected,
                    &--in-selecting-range {
                        background-color: #4c72bd;
                        color: #fff;
                    }

                    &--selected {
                        color: #fff;
                        background: #4c72bd;

                        &:focus {
                            outline-color: #484a53;
                        }
                    }

                    &.sprint-day {
                        border-radius: 0.3rem;
                        color: #fff;
                    }

                    &.color-1 {
                        background-color: #d8d95c;
                    }

                    &.color-2 {
                        background-color: #ee8282;
                    }

                    &.color-3 {
                        background-color: #4c72bd;
                    }
                }
            }
        }
    }
}

.selected-sprints {
    margin-bottom: 15px;

    .selected-sprints-content {
        min-height: 42px;
        display: flex;
        justify-content: space-between;

        .calenar-wrapper {
            width: 250px;
        }

        .calendar-values {
            width: calc(100% - 250px);
            padding: 0 15px;

            .data-value,
            .duration-value,
            .sprints-quantity-value {
                margin-bottom: 10px;

                .values-title {
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .value {
                    color: #8ec358;
                }
            }
        }

        .error-indicator {
            width: 100%;
        }
    }
}

.select-new-sprint {
    .description {
        margin-bottom: 15px;
    }

    .select-new-sprint-content {
        display: flex;
        justify-content: space-between;

        .calenar-wrapper {
            width: 250px;
        }

        .calendar-values {
            width: calc(100% - 250px);
            padding: 0 15px;

            div {
                margin-bottom: 15px;
                p {
                    font-size: 16px;
                    line-height: 24px;
                    color: #484a53;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .date-value {
                    color: #8ec358;

                    &.no-value {
                        color: #dc3545;
                    }
                }
            }
        }
    }
}
