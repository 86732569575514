.textarea-wrapper {
    margin-bottom: 20px;
    position: relative;

    label {
        font-weight: 500;
        margin-bottom: 10px;
        cursor: pointer;
    }

    textarea {
        height: 100px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 8px 50px 8px 15px;
        border-radius: 5px;
        border: 1px solid #484a53;
        outline: none;
        width: 100%;
        color: #484a53;
        transition: all 0.2s linear;

        &::placeholder {
            font-weight: normal;
        }

        &[readonly] {
            background-color: #fff;
        }
        &:focus {
            border-color: #4c72bd;
            color: #4c72bd;
            box-shadow: none;

            &::placeholder {
                color: #4c72bd;
            }
        }
    }
}
