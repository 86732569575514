.logs-list {
    margin-top: 10px;
    width: 100%;
    max-height: 1000px;
    overflow: hidden;
    overflow-y: auto;

    .loader {
        display: block;
        margin: 0 auto;
        padding: 10px 0;
        height: 58px;
    }

    .log {
        background-color: #484a53;
        border-bottom: 1px solid #fff;
        padding: 15px 15px;

        p {
            border-bottom: 1px dashed #fff;
            color: #fff !important;
            font-size: 14px !important;
            line-height: 24px !important;
            font-family: 'Roboto Mono', monospace;
        }
    }
}
