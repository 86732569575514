.leaks-chart {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    max-width: 1280px;

    .no-data {
        font-size: 20px;
        font-weight: 500;
        color: #4c72bd;
    }

    .chart-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .chart-canvas-wrapper {
        position: relative;
    }
}
